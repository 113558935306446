module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"3b5b90f9-4ca5-5973-827e-c3193465f8b5","name":"gatsby-remark-images","version":"3.3.4","pluginOptions":{"plugins":[],"maxWidth":700,"backgroundColor":"transparent","showCaptions":true},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":700,"backgroundColor":"transparent","showCaptions":true}},"gatsby-remark-copy-linked-files","gatsby-remark-embed-video",{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-autolink-headers","gatsby-remark-smartypants",{"resolve":"@weknow/gatsby-remark-twitter","options":{"align":"center"}},"gatsby-remark-external-links"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"backgroundColor":"transparent","showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mathieu Dutour","short_name":"Mathieu Dutour","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2b568ceac3e3e13fa0fc9f462ff065a9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-webmention/gatsby-browser.js'),
      options: {"plugins":[],"username":"mathieu.dutour.me/","identity":{"github":"mathieudutour","twitter":"mathieudutour"},"mentions":true,"pingbacks":true,"domain":"mathieu.dutour.me/","token":"_1hnEJHkf51qSgx3rAjpXA"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-goatcounter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-goatcounter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-medium-to-own-blog/gatsby-browser.js'),
      options: {"plugins":[],"pathPrefix":"/blog","config":{"title":"Mathieu Dutour","description":"I build things.","author":"Mathieu Dutour","siteUrl":"https://mathieu.dutour.me/","githubUrl":"https://github.com/mathieudutour/mathieudutour.github.io","bio":"","shortBio":"","social":{"twitter":"mathieudutour","github":"mathieudutour","instagram":"","facebook":"","linkedin":"","medium":"@mathieudutour"},"goatCounterCode":"mathieu_dutour_me"},"contentPath":"blog","webmentionsToken":"_1hnEJHkf51qSgx3rAjpXA"},
    }]
